import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import ProgBar from 'js/components/Dashboard/CyclePreview/ProgBar';
import DropdownItem from './DropdownItem';
import IconUsers from 'cccisd-icons/users';
import InfoLabel from 'js/components/InfoLabel';
import moment from 'moment';
import classnames from 'classnames';

export default class CycleStatus extends React.Component {
    static propTypes = {
        siteId: PropTypes.number,
        assignmentId: PropTypes.number,
        setSelectedCycle: PropTypes.func,
        selectedCycle: PropTypes.object,
        cycles: PropTypes.array,
        target: PropTypes.number,
        progress: PropTypes.number,
        responseRate: PropTypes.number,
        responseRateCount: PropTypes.number,
        drawTopBorder: PropTypes.bool,
        hideResponseRate: PropTypes.bool,
    };

    getStatus = (startDate, endDate) => {
        const now = moment();
        if (now.isBefore(moment(endDate)) && now.isAfter(moment(startDate))) {
            return 'Open';
        }
        if (now.isBefore(moment(startDate))) {
            return 'Upcoming';
        }
        return 'Closed';
    };

    getOngoingBanner = youthAdded => {
        const { count, date } = youthAdded;
        const formattedDate = moment(date.date).format('MM/DD/YYYY');
        return (
            <div className={classnames(style.countIt, style.greyText)}>
                {count} youth added on {formattedDate}
            </div>
        );
    };

    render() {
        const { selectedCycle, target, progress, responseRate, responseRateCount } = this.props;
        let openStatus;
        let isOngoing;
        if (selectedCycle) {
            openStatus = this.getStatus(selectedCycle.startDate, selectedCycle.endDate);
            isOngoing = selectedCycle.settings.cycleType === 'ongoing';
        }
        return (
            <div className={this.props.drawTopBorder ? style.statusBarAlt : style.statusBar}>
                <div className={style.dropdown}>
                    <div className={style.label}>Select Cycle</div>
                    <DropdownItem
                        setCycle={this.props.setSelectedCycle}
                        selectedCycle={this.props.selectedCycle}
                        options={this.props.cycles}
                    />
                </div>
                <div>
                    <div className={style.label}>
                        {isOngoing ? 'Current Sample Size' : 'Sample Size'}
                    </div>
                    <div className={style.bigNumber}>
                        <IconUsers spaceRight />
                        {target ? target : '--'}
                    </div>

                    {isOngoing &&
                        selectedCycle.settings.youthAdded &&
                        openStatus !== 'Closed' &&
                        this.getOngoingBanner(selectedCycle.settings.youthAdded)}
                </div>
                <div>
                    <div className={style.label}>Date Range</div>
                    <div>
                        {selectedCycle ? selectedCycle.startDate : '--'} -{' '}
                        {selectedCycle ? selectedCycle.endDate : '--'}
                    </div>
                    {selectedCycle && (
                        <div
                            className={
                                openStatus === 'Open'
                                    ? style.cycleStatusOpen
                                    : style.cycleStatusClosed
                            }
                        >
                            {openStatus}
                        </div>
                    )}
                </div>
                <div>
                    <div className={style.prog}>
                        <InfoLabel
                            label="Cycle Progress"
                            tooltip="Cycle Progress reflects the number of forms submitted, not the validity of the forms submitted."
                            hideDecorator
                        />
                        <ProgBar
                            prog={target > 0 ? Math.round((progress / target) * 100) : 0}
                            tooltip={false}
                            labels={[]}
                            placement="top"
                        />
                        {target > 0 && (
                            <div className={style.countIt}>
                                {progress} of {target}
                            </div>
                        )}
                    </div>
                    {!this.props.hideResponseRate && (
                        <div className={style.prog}>
                            <InfoLabel label="Response Rate" tooltip="" hideDecorator />
                            <ProgBar
                                prog={Math.round((responseRate / responseRateCount) * 100)}
                                tooltip={false}
                                labels={[]}
                                placement="top"
                            />
                            {responseRateCount > 0 && (
                                <div className={style.countIt}>
                                    {responseRate} of {responseRateCount}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
