import { createSelector } from 'reselect';

const getProjectList = state => state.projectList;
const getCurrentProjectId = state => state.currentProjectId;
const getCurrentSurveyListHandles = state => state.currentSurveyListHandles;
const getCurrentDeploymentId = (state, props) => Number(props.match.params.deploymentId);
export const getCurrentProject = createSelector(
    getProjectList,
    getCurrentProjectId,
    getCurrentSurveyListHandles,
    (projectList, currentProjectId, currentSurveyListHandles = {}) => {
        const result = projectList.find(project => project.groupId === currentProjectId);
        if (!result) {
            return null;
        }

        result.flows.assignmentList = result.flows.assignmentList.map(assignment => {
            if (!assignment.surveylistList) {
                return assignment;
            }

            const currentSurveylistList = currentSurveyListHandles[assignment.assignmentId]
                ? assignment.surveylistList.find(
                      item =>
                          item.surveylistHandle ===
                          currentSurveyListHandles[assignment.assignmentId]
                  )
                : assignment.surveylistList[0];
            return {
                ...assignment,
                currentSurveylistList,
            };
        });
        // we need shallow copy here just to update connected components
        return { ...result };
    }
);

export const getCurrentProjectAssignments = createSelector(getCurrentProject, currentProject => {
    if (!currentProject) {
        return [];
    }
    return currentProject.flows.assignmentList;
});

export const getCurrentProjectDeployments = createSelector(
    getCurrentProjectAssignments,
    assignments => {
        let result = [];
        assignments.forEach(item => {
            result = [...result, ...item.deploymentList];
        });

        return result;
    }
);

export const getDeploymentById = createSelector(
    getCurrentProjectDeployments,
    getCurrentDeploymentId,
    (deployments, deploymentId) => deployments.find(item => item.deploymentId === deploymentId)
);
