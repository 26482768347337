import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Breadcrumbs from './Breadcrumbs';
import Tabs from '../Tabs';
import TabView from './TabView';
import { loadGroups } from '../../reducers/sites.js';
import { setSite } from '../../reducers/data.js';

const user = window.cccisd.fortress.user.acting;
const isUber = user.data_type === 'uberadmin';
const licenses = {
    dart: !isUber ? user.group.type_data.dartLicense : 1,
    wfi: !isUber ? user.group.type_data.wfiLicense : 1,
    tom: !isUber ? user.group.type_data.tomLicense : 1,
};
const toolPermissions = !isUber ? JSON.parse(user.role_data.toolPermissions) : [];
class DataManagement extends React.Component {
    static propTypes = {
        // from redux
        site: PropTypes.number,
        loadGroups: PropTypes.func,
        setSite: PropTypes.func,
        groups: PropTypes.array,
    };

    state = {
        showClosed: false,
    };

    componentDidMount = async () => {
        this.props.loadGroups();
    };

    setSite = siteId => {
        this.props.setSite(siteId);
        localStorage.setItem('lastViewedSiteId', siteId);
    };

    setShowClosed = () => {
        this.setState(prevState => ({ showClosed: !prevState.showClosed }));
    };

    render() {
        const wfiTabs = [
            {
                name: 'careCoord',
                title: 'WFI-EZ Care Coordinator',
                content: (
                    <TabView
                        cycle="careCoord"
                        assignmentId={2}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
            {
                name: 'caregiver',
                title: 'WFI-EZ Caregiver',
                content: (
                    <TabView
                        cycle="caregiver"
                        assignmentId={3}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
            {
                name: 'teamMember',
                title: 'WFI-EZ Team Member',
                content: (
                    <TabView
                        cycle="teamMember"
                        assignmentId={4}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
            {
                name: 'youth',
                title: 'WFI-EZ Youth',
                content: (
                    <TabView
                        cycle="youth"
                        assignmentId={5}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
        ];

        const tabs = [
            {
                name: 'wfi',
                title: 'WFI-EZ',
                content: <Tabs level={1} tabId="wfiTabs" slim tabList={wfiTabs} />,
            },
            {
                name: 'tom',
                title: 'TOM 2.0',
                content: (
                    <TabView
                        cycle="tom"
                        assignmentId={1}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
            {
                name: 'dart',
                title: 'DART',
                content: (
                    <TabView
                        cycle="dart"
                        assignmentId={6}
                        showClosed={this.state.showClosed}
                        {...this.props}
                    />
                ),
            },
        ].filter(tab => {
            if (!isUber) {
                // if the group is allowed
                if (licenses[tab.name]) {
                    // if the user has permissions set
                    if (toolPermissions && toolPermissions.length > 0) {
                        return toolPermissions.includes(tab.name);
                    }
                    return true;
                }
            } else {
                // if uberadmin
                return true;
            }
            return false;
        });
        return (
            <div className={style.pageMargin}>
                <h2>Data Management</h2>
                {tabs.length > 0 ? (
                    <div>
                        <Breadcrumbs
                            siteId={this.props.site}
                            setSite={siteId => this.setSite(siteId)}
                            showClosed={this.state.showClosed}
                            setShowClosed={this.setShowClosed}
                        />
                        <Tabs tabList={tabs} tabId="wrapStatTools" />
                    </div>
                ) : (
                    <div>
                        <h4>Permission Denied</h4>
                        <h5>You have no tool access.</h5>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site: state.app.data.site,
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, { loadGroups, setSite })(DataManagement);
