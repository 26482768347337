import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

import style from './style.css';
const Boilerplate = window.cccisd.boilerplate;

export default class SetEmail extends React.Component {
    static propTypes = {
        pawnId: PropTypes.number,
        type: PropTypes.string,
        pawnHash: PropTypes.string,
        closeModal: PropTypes.func,
        reloadTable: PropTypes.func,
        closeParentModal: PropTypes.func,
    };

    state = {
        email: '',
    };

    isEmail = email => {
        return /\S+@\S+\.\S+/.test(email);
    };

    submit = async () => {
        if (this.isEmail(this.state.email)) {
            await axios.put(
                Boilerplate.route('api.nexus.pawn.update', { pawn: this.props.pawnId }),
                {
                    [`${this.props.type}Email`]: this.state.email,
                    pawnHash: this.props.pawnHash,
                }
            );
            await this.props.closeModal();
            this.props.closeParentModal();
            this.props.reloadTable();
        } else {
            notification({ message: 'Invalid email', type: 'danger' });
        }
    };

    render() {
        return (
            <div className={style.flexIt}>
                <input
                    type="text"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                />
                <button type="submit" className="btn btn-primary" onClick={this.submit}>
                    Save
                </button>
            </div>
        );
    }
}
