import React from 'react';
import PropTypes from 'prop-types';
import { Report } from 'cccisd-laravel-report';
import Template from './Template';

export default class YouthCoverSheet extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        endDate: PropTypes.string,
        tool: PropTypes.string,
    };

    render() {
        return (
            <Report height="500px">
                <Template
                    data={this.props.data}
                    tool={this.props.tool}
                    endDate={this.props.endDate}
                />
            </Report>
        );
    }
}
