import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { Page } from 'cccisd-laravel-report';
import logoImage from '../../../../../../../images/logo_color.png';
import { round } from 'lodash';

const getLang = shortName => {
    if (shortName === 'es') {
        return 'Spanish';
    }
    if (shortName === 'en') {
        return 'English';
    }
    return 'Unknown';
};

export default class Template extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        endDate: PropTypes.string,
        tool: PropTypes.string,
    };

    renderDataItem = (label, value) => {
        return (
            <div key={value}>
                <h4 className={style.itemLabel}>{label}</h4>
                <h3 className={style.itemValue}>{value}</h3>
            </div>
        );
    };

    render() {
        const { data } = this.props;
        const youthInfo = [
            [
                ['Youth Wraparound ID', data['pawn.fields.wrapId'] || 'N/A'],
                ['Enrollment Start Date', data['pawn.fields.startDate'].split(' ')[0] || 'N/A'],
                ['Age at Enrollment', data['pawn.fields.ageAtEnrollment'] || 'N/A'],
                ["Youth's Preferred Language", getLang(data['pawn.fields.language']) || 'N/A'],
                ['Gender Identity', data['pawn.fields.gender'] || 'N/A'],
                ['Race', data['pawn.fields.race'] || 'N/A'],
                ['Ethnicity', data['pawn.fields.ethnicity'] || 'N/A'],
            ],
            [
                ['Site Name', data['pawn.parentGroup.site.group.label'] || 'N/A'],
                ['Site ID', data['pawn.parentGroup.site.group.groupId'] || 'N/A'],
                ['Care Coordinator', data.careCoordinatorFullName || 'N/A'],

                [
                    'Care Coordinator ID',
                    data['pawn.parentRoles.careCoordinator.pawn.pawnId' || 'N/A'],
                ],
                [
                    'Care Coordinator Contact Information',
                    data['pawn.parentRoles.careCoordinator.fields.email' || 'N/A'],
                ],
                ['Evaluation Cycle End Date', this.props.endDate || 'N/A'],
            ],
        ];
        const tomScores = [
            [
                [
                    'Overall Score',
                    round(data['assignmentProgress.devTags.TomOverallScore'], 2) || 0,
                ],
                [
                    'Key Element Score',
                    round(data['assignmentProgress.devTags.TomKeyElementsScore'], 2) || 0,
                ],
            ],
            [
                [
                    'Full Meeting Attendance',
                    round(data['assignmentProgress.devTags.TomFullMeetingAttendanceScore'], 2) || 0,
                ],
                [
                    'Effective Teamwork',
                    round(data['assignmentProgress.devTags.TomEffectiveTeamworkScore'], 2) || 0,
                ],
                [
                    'Driven by Strengths and Families',
                    round(data['assignmentProgress.devTags.TomStrengthsFamiliesScore'], 2) || 0,
                ],
                [
                    'Based on Priority Needs',
                    round(data['assignmentProgress.devTags.TomPriorityNeedsScore'], 2) || 0,
                ],
                [
                    'Use of Natural and Community Supports',
                    round(data['assignmentProgress.devTags.TomNaturalCommunityScore'], 2) || 0,
                ],
                [
                    'Outcomes-Based Process',
                    round(data['assignmentProgress.devTags.TomOutcomesBasedScore'], 2) || 0,
                ],
                [
                    'Skilled Facilitation',
                    round(data['assignmentProgress.devTags.TomSkilledFacilitationScore'], 2) || 0,
                ],
            ],
        ];
        const dartScores = [
            [
                [
                    'Key Element Score',
                    round(data['assignmentProgress.devTags.DartKeyElementsScore'], 2) || 0,
                ],
                [
                    'Outcomes Score',
                    round(data['assignmentProgress.devTags.DartOutcomesScore'], 2) || 0,
                ],
                [
                    'Needs-Based',
                    round(data['assignmentProgress.devTags.DartNeedsBasedScore'], 2) || 0,
                ],
                [
                    'Outcomes-Based',
                    round(data['assignmentProgress.devTags.DartOutcomesBasedScore'], 2) || 0,
                ],
                [
                    'Transition Planning',
                    round(data['assignmentProgress.devTags.DartTransitionPlanningScore'], 2) || 0,
                ],
                [
                    'Driven by Strengths and Families',
                    round(data['assignmentProgress.devTags.DartStrengthsFamiliesScore'], 2) || 0,
                ],
                [
                    'Meeting Attendance',
                    round(data['assignmentProgress.devTags.DartMeetingAttendanceScore'], 2) || 0,
                ],
                ['Transition Reason', data['assignmentProgress.devTags.DartTransitionReasonScore']],
                [
                    'Timely Engagement',
                    round(data['assignmentProgress.devTags.DartTimelyEngagementScore'], 2) || 0,
                ],
                [
                    'Natural and Community Supports',
                    round(data['assignmentProgress.devTags.DartNaturalCommunityScore'], 2) || 0,
                ],
                [
                    'Safety Planning',
                    round(data['assignmentProgress.devTags.DartSafetyPlanningScore'], 2) || 0,
                ],
                [
                    'Crisis Response',
                    round(data['assignmentProgress.devTags.DartCrisisResponseScore'], 2) || 0,
                ],
            ],
        ];
        return (
            <>
                <Page>
                    <div className={style.page}>
                        <div className={style.topLine} />
                        <div className={style.header}>
                            <div className={style.logo}>
                                <img
                                    alt=""
                                    height="75px"
                                    style={{ visibility: 'hidden' }}
                                    src={logoImage}
                                />
                            </div>
                            <div className={style.surveyName}>{this.props.tool} Survey</div>
                        </div>
                        <h2 className={style.title}>Youth Information</h2>
                        <hr className={style.hr} />
                        <div className={style.cols}>
                            {youthInfo.map(col => {
                                return (
                                    <div key={col}>
                                        {col.map(item => {
                                            return this.renderDataItem(item[0], item[1]);
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Page>

                {data['assignmentProgress.assignmentId'] === 1 &&
                    data['assignmentProgress.status'] === 'Complete' &&
                    data['assignmentProgress.devTags.OptOutBoolean'] !== '0' && (
                        <Page>
                            <div className={style.page}>
                                <h2 className={style.title}>TOM 2.0 Scores</h2>
                                <hr className={style.hr} />
                                <div className={style.cols}>
                                    {tomScores.map(col => {
                                        return (
                                            <div key={col}>
                                                {col.map(item => {
                                                    return this.renderDataItem(item[0], item[1]);
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Page>
                    )}
                {data['assignmentProgress.assignmentId'] === 6 &&
                    data['assignmentProgress.status'] === 'Complete' &&
                    data['assignmentProgress.devTags.OptOutBoolean'] !== '0' && (
                        <Page>
                            <div className={style.page}>
                                <h2 className={style.title}>DART Scores</h2>
                                <hr className={style.hr} />
                                <div className={style.cols}>
                                    {dartScores.map(col => {
                                        return (
                                            <div key={col}>
                                                {col.map(item => {
                                                    return this.renderDataItem(item[0], item[1]);
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Page>
                    )}
            </>
        );
    }
}
