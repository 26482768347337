import React, { useState } from 'react';
import _get from 'lodash/get';
import { client as apollo } from 'cccisd-apollo';
import Modal from 'cccisd-modal';
import pawnMessagesQuery from 'js/graphql/pawnMessagesQuery.graphql';
import { RenderLoggedMessage } from 'cccisd-laravel-nexus';

const LoggedPawnMessages = ({ deploymentId, row }) => {
    const [messages, setMessages] = useState([]);

    const messageCount = row.messageCount;
    const pawnId = row.pawnId;

    const getMessages = async () => {
        if (messageCount > 0 && messageCount !== messages.length) {
            let result = await apollo.query({
                query: pawnMessagesQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentId,
                    pawnId,
                },
            });
            let resultMessages = _get(result, 'data.messages.messageList');
            setMessages(resultMessages);
        }
    };

    if (!messageCount) {
        return <div>{messageCount}</div>;
    }

    return (
        <Modal
            trigger={
                <a href="" onClick={getMessages}>
                    {messageCount}
                </a>
            }
            title="Sent Messages"
        >
            {messages.map((message, i) => {
                const separator = i < messages.length - 1 ? <hr /> : null;
                const content = <RenderLoggedMessage {...message} />;

                return (
                    <div key={message.messageId}>
                        {content}
                        {separator}
                    </div>
                );
            })}
        </Modal>
    );
};

export default LoggedPawnMessages;
