import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import classnames from 'classnames';

export default class DropdownItem extends React.Component {
    static propTypes = {
        setCycle: PropTypes.func,
        options: PropTypes.array,
        selectedCycle: PropTypes.object,
    };

    render() {
        const { options } = this.props;
        return (
            <div>
                <div className="dropdown">
                    <button
                        className={classnames(
                            'btn',
                            'btn-default',
                            'dropdown-toggle',
                            style.dropdown
                        )}
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                    >
                        {this.props.selectedCycle && this.props.selectedCycle.label}
                        <span className={classnames(style.caret, 'caret')} />
                    </button>
                    <ul className="dropdown-menu">
                        {options.map(item => (
                            <li key={item.value}>
                                <a onClick={() => this.props.setCycle(item.value)}>{item.label}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}
