export default condition => {
    const knownConditions = {
        all_respondents: 'All Respondents',
        complete: 'Completed',
        incomplete: 'Partially Completed',
        incomplete_or_not_started: 'Partially Completed or Not Started',
        not_started: 'Not Started',
        on_respondent_complete: 'On Respondent Complete',
        on_respondent_complete_notify: 'On Respondent Complete',
        scheduled: 'Scheduled',
        scheduled_on_respondent_complete: 'On Respondent Complete (Schedule Offset)',
        scheduled_on_respondent_complete_notify: 'On Respondent Complete (Schedule Offset)',
        scheduled_target_met: 'Target Met (Schedule Offset)',
        target_met: 'Target Met',
        target_not_met: 'Target Not Met',
    };

    return knownConditions[condition] || condition;
};
