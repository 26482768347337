import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage, Logs as AppdefLogs } from 'cccisd-laravel-appdefs';
import { Quest } from 'cccisd-laravel-assignment';
import ReportCenter from 'cccisd-report-center';
import { ResourceManager } from 'cccisd-laravel-resources';

// Layouts
// import FluidLayout from './layouts/FluidLayout';
import FixedLayout from './layouts/FixedLayout';
import FluidLayout from './layouts/FluidLayout';
import EmptyLayout from './layouts/EmptyLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Laravel packages
import EvaluationCycles from 'bundle-loader?lazy!./components/EvaluationCycles';
import YouthRostering from 'bundle-loader?lazy!./components/YouthRostering';
import ResourceCenter from 'bundle-loader?lazy!./components/ResourceCenter';
import Dashboard from 'bundle-loader?lazy!./components/Dashboard';
import Outcomes from 'bundle-loader?lazy!./components/Outcomes';
import Reports from 'bundle-loader?lazy!./components/Reports';
import DataManagement from 'bundle-loader?lazy!./components/DataManagement';
import SurveyPreview from './pages/SurveyPreview'; // flow preview
import ExportData from './pages/ExportData'; // Quest Export tab
import ToolSurvey from './pages/ToolSurvey';
import ViewData from './pages/ViewData';

// Bind FixedLayout by default
const AppRoute = defaultProps({ layout: FixedLayout })(Route);

var AppDefinitions = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;

const match = {
    AppdefManage,
    EvaluationCycles,
    Outcomes,
    YouthRostering,
    ResourceCenter,
    SurveyPreview,
    Dashboard,
    Reports,
    ReportCenter,
    DataManagement,
    ExportData,
    ToolSurvey,
    ViewData,
    AppdefLogs,
};

const layoutMatch = {
    FluidLayout,
    FixedLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            if (Fortress.user.acting.role.handle !== 'uberadmin') {
                const permissions = Fortress.user.acting.role_data.permissions;
                const routeAllowed = permissions.includes(route.handle);

                if (!routeAllowed) {
                    return null;
                }
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : FixedLayout}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact />
        {<AppRoute path="/surveyPreview/:flowId" component={SurveyPreview} />}

        <AppRoute
            path="/d/:deploymentHash/:shortHash"
            component={ToolSurvey}
            componentProps={{
                errorLayout: EmptyLayout,
            }}
            layout={FixedLayout}
        />

        <AppRoute
            path="/d/:deploymentHash"
            component={Quest}
            componentProps={{
                errorLayout: EmptyLayout,
            }}
            layout={FixedLayout}
        />

        {/* Laravel packages routes */}
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />

        {Fortress.auth() &&
            (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) &&
            AdminRoutes(FixedLayout)}

        <AppRoute path="/account" component={Nexus} />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        <AppRoute path="/reportCenter" component={ReportCenter} exact />

        {Fortress.auth() && Fortress.isSuperUser() && AdminRoutes(FixedLayout)}

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/ViewData" component={ExportData} />
        )}

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/appdefs/logs" component={AppdefLogs} />
        )}

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/resources/admin" component={ResourceManager} />
        )}

        {Fortress.auth() && <AppRoute path="/resourceCenter/:tab?/:id?" component={ResourceCenter} />}

        {/* Not found page */}
        <AppRoute component={AuthRedirect} componentProps={{ isComponentOnly: true }} />
    </Switch>
);
