import React from 'react';
import PropTypes from 'prop-types';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconProgress from 'cccisd-icons/spinner3';
import IconWarn from 'cccisd-icons/warning2';
import IconBlock from 'cccisd-icons/blocked';
import Tooltip from 'cccisd-tooltip';

import style from './style.css';
import classnames from 'classnames';

export default class StatusIcon extends React.Component {
    static propTypes = {
        status: PropTypes.string,
        tooltip: PropTypes.string,
    };

    render() {
        const { status } = this.props;

        if (status === 'notstarted') {
            return (
                <div className={classnames(style.notStarted, style.icon)}>
                    <Tooltip title="Not Started" placement="bottom">
                        <IconWarn />
                    </Tooltip>
                </div>
            );
        }
        if (status === 'inprogress') {
            return (
                <div className={classnames(style.progress, style.icon)}>
                    <Tooltip title="In Progress" placement="bottom">
                        <IconProgress />
                    </Tooltip>
                </div>
            );
        }
        if (status === 'completed') {
            return (
                <div className={classnames(style.completed, style.icon)}>
                    <Tooltip title="Completed" placement="bottom">
                        <IconCheck />
                    </Tooltip>
                </div>
            );
        }
        if (status === 'optout') {
            return (
                <div className={classnames(style.optOut, style.icon)}>
                    <Tooltip title={this.props.tooltip} placement="bottom">
                        <IconBlock />
                    </Tooltip>
                </div>
            );
        }
        if (status === 'noconsent') {
            return (
                <div className={classnames(style.optOut, style.icon)}>
                    <Tooltip title="Declined to participate" placement="bottom">
                        <IconBlock />
                    </Tooltip>
                </div>
            );
        }
    }
}
