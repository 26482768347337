import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Axios from 'cccisd-axios';
import { Formik, Form, Field, CccisdInput, CccisdSelect, CccisdTextarea } from 'cccisd-formik';
import HiddenBlock from 'cccisd-hidden-block';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import MessageViewer from '../MessageViewer';
import { reloadCurrentProject } from 'js/reducers/message.js';
import conditionTranslator from './messageConditionTranslator.js';
import style from './style.css';
import { client as apollo } from 'cccisd-apollo';
import deploymentMessageListQuery from 'js/graphql/deploymentMessageList.graphql';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

class SendMessageNow extends React.Component {
    static propTypes = {
        rtComponent: PropTypes.object,

        // From Redux
        reloadCurrentProject: PropTypes.func,
        deployment: PropTypes.object,

        // From Modal
        closeModal: PropTypes.func,

        // Required for getDeploymentById to work!
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        closeModal: () => {},
    };

    state = {
        loading: true,
        method: undefined,
        recipientCounts: {},
        templateId: -1,
    };

    componentDidMount = async () => {
        await this.getDeploymentMessages();
        if (!this.getSelectedRows().length) {
            this.loadCounts();
        } else {
            this.setState({
                loading: false,
            });
        }
    };

    getDeploymentMessages = async () => {
        const { deploymentId } = this.props.deployment;
        const result = await apollo.query({
            query: deploymentMessageListQuery,
            variables: { deploymentId },
            fetchPolicy: 'network-only',
        });
        const { deploymentMessageList } = result.data.flows.deployment;
        this.setState({ deploymentMessageList });
    };

    loadCounts = async () => {
        const response = await Axios.get(
            Boilerplate.route(`api.assignmentDeploymentMessage.recipientCounts`, {
                deploymentId: this.props.deployment.deploymentId,
            })
        );
        const recipientCounts = response.status === 200 ? response.data.data : {};
        await this.setState({ loading: false, recipientCounts });
    };

    getSelectedRows = () => {
        return this.props.rtComponent.state.selectedRows;
    };

    getSelectedRowData = () => {
        const tableState = this.props.rtComponent.state;
        return tableState.data.filter(row => {
            return tableState.selectedRows.includes(row.pawnId);
        });
    };

    getEligibleRows = values => {
        const allRows = this.getSelectedRowData();
        const method = values.method;
        if (this.hasRecipientChain(values)) {
            return allRows;
        }
        return allRows.filter(row => {
            if (method === 'email') {
                return row[`${this.props.rtComponent.state.userPath}user.email`];
            }
            if (method === 'sms') {
                return row[`${this.props.rtComponent.state.userPath}user.phone`];
            }
            return false;
        });
    };

    getPreviewMessageObject = values => {
        const msg = this.state.deploymentMessageList.find(e => e.messageId === values.template);
        const msgLabel = msg ? msg.settings.label : '';
        const condition = !this.getSelectedRows().length && msg ? msg.condition : 'scheduled';
        const eligiblePawnIds = this.getEligibleRows(values).map(e => e.pawnId);
        const recipients = eligiblePawnIds.length ? eligiblePawnIds : [];

        let msgObj = {
            condition,
            messageMethod: values.method,
            category: 'preview',

            recipients,

            settings: {
                label: msgLabel,
                recipientsType: 'respondent',
            },
        };
        let messageInfo =
            values.method === 'email'
                ? {
                      subject: values.subject,
                      body: values.body,
                      outroLines: values.outroLines,
                      actionText: values.actionText,
                      actionUrl: values.actionUrl,
                  }
                : {
                      body: values.body,
                  };

        msgObj.messageInfo = messageInfo;

        return msgObj;
    };

    hasRecipientChain = values => {
        const { settings } = this.props.deployment;
        const chainPath = values.method === 'email' ? 'chainEmailFromPawn' : 'chainPhoneFromPawn';
        const recipientChain = settings && settings[chainPath];
        return !!recipientChain;
    };

    renderSendInfo = (values, selectedTemplate) => {
        const { recipientCounts } = this.state;
        const selectedRows = this.getSelectedRows();
        const eligibleSelected = this.getSelectedRowData().filter(row => {
            if (values.method === 'email') {
                return row[`${this.props.rtComponent.state.userPath}user.email`];
            }
            if (values.method === 'sms') {
                return row[`${this.props.rtComponent.state.userPath}user.phone`];
            }
            return false;
        });
        const hasRecipientChain = this.hasRecipientChain(values);
        const allIneligible =
            selectedRows.length && !hasRecipientChain ? eligibleSelected.length === 0 : false;
        const recipientCountData = recipientCounts[selectedTemplate.condition] || {};
        const recipientCount = recipientCountData[values.method];
        const recipientTotal = recipientCountData.total;
        const conditionName = conditionTranslator(selectedTemplate.condition);
        let sendInfo = selectedRows.length
            ? `Send to the ${eligibleSelected.length} ${
                  eligibleSelected.length !== selectedRows.length ? 'eligible' : ''
              } selected.${
                  selectedRows.length === eligibleSelected.length
                      ? ''
                      : ` (${selectedRows.length - eligibleSelected.length} selected without ${
                            values.method === 'email' ? 'an email address' : 'a phone number'
                        }.)`
              }`
            : `Send to the ${recipientCount} ${
                  recipientTotal > recipientCount ? 'eligible' : ''
              } respondent${recipientCount === 1 ? '' : 's'} ${
                  selectedTemplate.condition === 'all_respondents'
                      ? ''
                      : `meeting condition "${conditionName}"`
              }${
                  recipientTotal === recipientCount
                      ? ''
                      : ` (${recipientTotal - recipientCount} other${
                            recipientTotal - recipientCount > 1 ? 's' : ''
                        } without ${
                            values.method === 'email' ? 'an email address' : 'a phone number'
                        }).`
              }`;
        if (allIneligible) {
            return (
                <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                    All selected ({selectedRows.length}) have an empty{' '}
                    {values.method === 'email' ? 'email address' : 'phone number'}.
                </div>
            );
        }
        if (!selectedRows.length && recipientCount === 0) {
            if (recipientTotal === 0) {
                return (
                    <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                        {`No respondents match the criteria "${conditionName}".`}
                    </div>
                );
            }
            if (!hasRecipientChain) {
                return (
                    <div className={`alert alert-danger ${style.sendInfo}`} role="alert">
                        {`All respondents (${recipientTotal}) ${
                            selectedTemplate.condition === 'all_respondents'
                                ? ''
                                : `meeting condition "${conditionName}"`
                        }
                     have an empty ${
                         values.method === 'email' ? 'email address' : 'phone number'
                     }.`}
                    </div>
                );
            }
        }
        if (hasRecipientChain) {
            sendInfo = selectedRows.length
                ? `Attempt to send to the ${selectedRows.length} selected respondents.`
                : `Attempt to send to all ${recipientTotal} respondents meeting condition "${conditionName}".`;
        }

        return (
            <div className={`alert alert-info ${style.sendInfo}`} role="alert">
                {sendInfo}
            </div>
        );
    };

    hasEligibleRecipients = values => {
        const { recipientCounts } = this.state;
        const { method, template } = values;
        const selectedRows = this.getSelectedRows();
        const selectedTemplate = this.state.deploymentMessageList.find(
            e => e.messageId === template
        );
        const conditionCounts = recipientCounts[selectedTemplate.condition] || {};
        if (this.hasRecipientChain(values)) {
            return selectedRows.length || conditionCounts.total;
        }
        if (selectedRows.length) {
            const eligibleSelected = this.getSelectedRowData().filter(row => {
                if (method === 'email') {
                    return row[`${this.props.rtComponent.state.userPath}user.email`];
                }
                if (method === 'sms') {
                    return row[`${this.props.rtComponent.state.userPath}user.phone`];
                }
                return false;
            });
            return eligibleSelected.length > 0;
        }
        return conditionCounts[method] > 0;
    };

    /* /////////////////////////////////////////////////////////////////////////
    // FORMIK METHODS /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    onSubmit = async values => {
        let formattedValues = this.getPreviewMessageObject(values);
        delete formattedValues.category;
        formattedValues.scheduleTime = moment
            .utc()
            .add(20, 'seconds')
            .format();

        const response = await Axios.post(
            Boilerplate.route(`api.assignmentDeploymentMessage.store`, {
                deploymentId: this.props.deployment.deploymentId,
            }),
            formattedValues
        );

        if (response.data.status === 'success') {
            this.props.closeModal();
            this.props.rtComponent.setStateAsync({ selectedRows: [] });
            this.props.reloadCurrentProject();

            return notification({
                type: 'success',
                message: `Successfully scheduled respondent message "${formattedValues.settings.label}"`,
            });
        }

        notification({
            type: 'danger',
            message: `Failed to schedule respondent message "${formattedValues.settings.label}"`,
            duration: 5000,
        });
    };

    validate = values => {
        let errors = {};

        if (!this.hasEligibleRecipients(values)) {
            errors._selected = 'All selected are invalid';
        }

        if (values.method === 'email') {
            if (values.actionText && !values.actionUrl) {
                errors.actionUrl = 'You must supply a Button URL if Button Text is provided';
            }
            if (!values.actionText && values.actionUrl) {
                errors.actionText = 'You must supply Button Text if a Button URL is provided';
            }
            if (!values.subject) {
                errors.subject = 'You must supply a Subject';
            }
            if (!values.body && !values.outroLines) {
                errors.body = 'You must supply either Body (upper) or Body (lower)';
                errors.outroLines = 'You must supply either Body (upper) or Body (lower)';
            }
        } else if (!values.body) {
            errors.body = 'You must supply a Body';
        }

        return errors;
    };

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER /////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    render() {
        const { loading, templateId } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        // console.log(deployment);
        const respondentConditions = [
            'all_respondents',
            'complete',
            'incomplete',
            'incomplete_or_not_started',
            'not_started',
            'on_respondent_complete',
        ];
        const templates = this.state.deploymentMessageList.filter(msg => {
            return msg.category === 'TEMPLATE' && respondentConditions.includes(msg.condition);
        });
        // console.log(templates);
        const templateOptions = templates.map(item => {
            return {
                label: item.settings.label,
                value: item.messageId,
            };
        });

        const methodOptions = [
            { label: 'Email Message', value: 'email' },
            { label: 'SMS/Text Message', value: 'sms' },
        ];

        let selectedTemplate = templates.find(e => e.messageId === templateId);
        if (!selectedTemplate && templates.length) {
            selectedTemplate = templates[0];
        }

        let initialValues = { method: 'email' };
        if (selectedTemplate) {
            initialValues = Object.assign(initialValues, {
                template: selectedTemplate.messageId,
                subject: selectedTemplate.messageInfo.subject || '',
                body: selectedTemplate.messageInfo.body || '',
                outroLines: selectedTemplate.messageInfo.outroLines || '',
                actionText: selectedTemplate.messageInfo.actionText || '',
                actionUrl: selectedTemplate.messageInfo.actionUrl || '',
                method: selectedTemplate.messageMethod,
            });
        } else {
            const messageLink = this.props.match.url.replace('/track/', '/messaging/');
            return (
                <div className="alert alert-danger" role="alert">
                    You must first create a template message in the{' '}
                    {
                        <Link className="alert-link" to={messageLink}>
                            Messages
                        </Link>
                    }{' '}
                    area.
                </div>
            );
        }

        const initialMethod = initialValues.method;
        if (this.state.method) {
            if (initialMethod !== this.state.method) {
                initialValues.body = '';
            }
            initialValues.method = this.state.method;
        }

        const sendInfo = this.renderSendInfo(initialValues, selectedTemplate);

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    render={props => (
                        <Form>
                            <Field
                                name="template"
                                component={CccisdSelect}
                                label="Template (by Label): *"
                                options={templateOptions}
                                onChange={e => {
                                    props.handleChange(e);
                                    this.setState({
                                        templateId: Number.parseInt(e.target.value, 10),
                                    });
                                }}
                            />
                            <Field
                                name="method"
                                component={CccisdSelect}
                                label="Message Type:"
                                options={methodOptions}
                                onChange={e => {
                                    props.handleChange(e);
                                    if (initialMethod !== e.target.value) {
                                        this.editMessageBlock.setShow(true);
                                    }
                                    this.setState({
                                        method: e.target.value,
                                    });
                                }}
                            />
                            <HiddenBlock
                                title="Edit Message"
                                ref={e => {
                                    this.editMessageBlock = e;
                                }}
                            >
                                {props.values.method === 'email' && (
                                    <Field
                                        name="subject"
                                        component={CccisdInput}
                                        label="Subject: *"
                                    />
                                )}
                                <Field
                                    name="body"
                                    component={CccisdTextarea}
                                    label={`Body${
                                        props.values.method === 'email' ? ' (upper)' : ''
                                    }:${props.values.method === 'sms' ? ' *' : ''}`}
                                />
                                {props.values.method === 'email' && (
                                    <>
                                        <Field
                                            name="actionText"
                                            component={CccisdInput}
                                            label="Button Text:"
                                        />
                                        <Field
                                            name="actionUrl"
                                            component={CccisdInput}
                                            label="Button URL:"
                                        />
                                        <Field
                                            name="outroLines"
                                            component={CccisdTextarea}
                                            label="Body (lower):"
                                        />
                                    </>
                                )}
                            </HiddenBlock>
                            {sendInfo}
                            <Modal
                                trigger={
                                    <button
                                        type="button"
                                        className={`btn btn-default ${style.button}`}
                                    >
                                        Preview
                                    </button>
                                }
                                title="Previewing message"
                                size={
                                    props.values.method &&
                                    props.values.method.toUpperCase() === 'EMAIL'
                                        ? 'large'
                                        : 'small'
                                }
                            >
                                <MessageViewer
                                    messageObject={this.getPreviewMessageObject(props.values)}
                                />
                            </Modal>
                            <button
                                className={`btn btn-primary ${style.button}`}
                                type="submit"
                                disabled={props.isSubmitting}
                            >
                                Send Message (Right Now)
                            </button>
                        </Form>
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, { reloadCurrentProject })(SendMessageNow);
