import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _map from 'lodash/map';
// import { Html } from 'cccisd-wysiwyg';
import conditionTranslator from '../SendMessageNow/messageConditionTranslator.js';

import style from './style.css';

export default class MessageViewer extends React.Component {
    static propTypes = {
        messageObject: PropTypes.object.isRequired,
        sendDateStr: PropTypes.string,
    };

    static defaultProps = {
        sendDateStr: '',
    };

    showLines = string => {
        if (!string) {
            return null;
        }
        const lines = string.split('\n\n');
        return lines.map((e, i) => {
            const br = i === lines.length - 1 ? null : <br />;
            return (
                <React.Fragment key={i}>
                    <div>{e}</div>
                    {br}
                </React.Fragment>
            );
        });
    };

    // info area
    _renderInfoArea = method => {
        const { messageObject } = this.props;
        const condition = conditionTranslator(messageObject.condition);

        return (
            <div className={style.infoArea}>
                {method === 'sms' && this._renderTags()}

                <div className={style.infoLine}>
                    <label>Label:</label>
                    <p>{messageObject.settings.label}</p>
                </div>
                {condition.toUpperCase() !== 'TEMPLATE' && (
                    <div className={style.infoLine}>
                        <label>Condition:</label>
                        <p>{condition}</p>
                    </div>
                )}
                <div className={style.infoLine}>
                    <label>Message Type:</label>
                    <p>{method === 'email' ? 'Email' : 'SMS/Text'}</p>
                </div>

                <div className={style.flexSpace} />

                {method === 'email' && this._renderTags()}
            </div>
        );
    };

    // table tags
    _renderTags = () => {
        const { category } = this.props.messageObject;
        const ucCategory = category.toUpperCase();
        return (
            <>
                {['DRAFT', 'TEMPLATE', 'PREVIEW'].includes(ucCategory) && (
                    <div className={classnames('label label-default', style.noteLabel)}>
                        <span>{ucCategory.charAt(0) + ucCategory.substr(1).toLowerCase()}</span>
                    </div>
                )}
                {ucCategory === 'SCHEDULED' && this.props.messageObject.scheduleTime !== null && (
                    <div className={classnames('label label-info', style.noteLabel)}>
                        <span>Scheduled</span>
                    </div>
                )}
                {ucCategory === 'COMPLETED' && (
                    <div className={classnames('label label-success', style.noteLabel)}>
                        <span>Past</span>
                    </div>
                )}
            </>
        );
    };

    // list of recipients
    _renderRecipientList = () => {
        const { messageObject } = this.props;

        if (messageObject.settings.recipientsType.toUpperCase() === 'RESPONDENT') {
            return (
                <ul className={style.recipients}>
                    <li className="label label-warning">Respondent(s)</li>
                </ul>
            );
        }

        return (
            <ul className={style.recipients}>
                {_map(messageObject.recipients, recipient => {
                    return (
                        <li key={recipient} className="label label-default">
                            {recipient}
                        </li>
                    );
                })}
            </ul>
        );
    };

    render() {
        const { messageObject, sendDateStr } = this.props;
        const condition = conditionTranslator(messageObject.condition);
        const category = messageObject.category.toUpperCase();

        if (messageObject.messageMethod.toUpperCase() === 'EMAIL') {
            // RETURN - email
            return (
                <div className={classnames(style.messageViewerWrapper, style.email)}>
                    {this._renderInfoArea('email')}

                    <div className={style.sendArea}>
                        <div>
                            <label>To:</label>
                            {this._renderRecipientList()}
                        </div>

                        {sendDateStr !== '' && (
                            <div>
                                <label>
                                    {['DRAFT', 'SCHEDULED'].includes(category)
                                        ? 'Send On:'
                                        : 'Sent On:'}
                                </label>
                                <div>
                                    {sendDateStr.length <= 1 ? `(${condition})` : sendDateStr}
                                </div>
                            </div>
                        )}

                        <div>
                            <label>Subject:</label>
                            <div>{messageObject.messageInfo.subject}</div>
                        </div>
                    </div>

                    <div className={style.messageArea}>
                        {this.showLines(messageObject.messageInfo.body)}

                        {messageObject.messageInfo.actionUrl &&
                            messageObject.messageInfo.actionText && (
                                <div className={style.button}>
                                    <a
                                        className="btn btn-lg btn-success"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={messageObject.messageInfo.actionUrl}
                                    >
                                        {messageObject.messageInfo.actionText}
                                    </a>
                                </div>
                            )}
                        {messageObject.messageInfo.actionUrl && (
                            <div>
                                <label>
                                    {messageObject.messageInfo.actionText} URL:&nbsp;&nbsp;
                                </label>
                                <a
                                    className="text-primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={messageObject.messageInfo.actionUrl}
                                >
                                    {messageObject.messageInfo.actionUrl}
                                </a>
                            </div>
                        )}

                        <br />

                        {this.showLines(messageObject.messageInfo.outroLines)}
                    </div>
                </div>
            );
        }

        // RETURN - sms
        return (
            <div className={classnames(style.messageViewerWrapper, style.sms)}>
                {this._renderInfoArea('sms')}

                <div className={style.sendArea}>
                    <div>{this._renderRecipientList()}</div>
                </div>

                <div className={style.messageArea}>
                    {sendDateStr !== '' && sendDateStr.length <= 1 ? (
                        <p>({condition})</p>
                    ) : (
                        <p>{sendDateStr}</p>
                    )}
                    <div>{this.props.messageObject.messageInfo.body}</div>
                </div>
            </div>
        );
    }
}
