import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

export default class ContactCard extends React.Component {
    static propTypes = {
        contact: PropTypes.array,
    };

    render() {
        return (
            <div>
                {this.props.contact.map(item => (
                    <div key={item.label}>
                        <h4 className={style.heading}>{item.label}</h4>
                        <h5 className={style.text}>
                            {typeof item.value === 'string' &&
                            (item.value === null ||
                                item.value === 'null null' ||
                                item.value.split(' ').includes('null') ||
                                item.value === 'null null, null null' ||
                                item.value === 'null, null, null' ||
                                item.value === 'undefined undefined, undefined undefined')
                                ? ''
                                : item.value}
                        </h5>
                    </div>
                ))}
            </div>
        );
    }
}
