import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import AdminForm from 'js/components/AdminForm';
import axios from 'cccisd-axios';
import { connect } from 'react-redux';
import IconPlus from 'cccisd-icons/plus-circle2';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

class Component extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        settings: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    // need to verify user is not existing for same group
    onSubmit = async values => {
        let data = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            adminId: values.adminId,
            group: values.group,
            adminLabel: values.adminLabel,
            permissions: JSON.stringify(values.permissions),
            toolPermissions: values.toolPerms === [] ? null : JSON.stringify(values.toolPerms),
            role: this.props.settings.adminType,
            adminType: this.props.settings.adminType,
            supervisor: values.supervisor,
        };

        if (values.existingUser) {
            // use only if user check returns existing user
            data.user = values.email;
        } else {
            // user if user check returns new user
            data.username = values.email;
        }
        const result = await axios.post(Boilerplate.route('api.nexus.pawn.store'), data);
        if (result.data.status === 'error') {
            if (result.data.errors.association?.length > 0) {
                notification({
                    type: 'danger',
                    message: `Adding user failed: ${result.data.errors.association[0]}`,
                });
            } else {
                notification({ type: 'danger', message: `Adding user failed.` });
            }
            throw result.data.errors;
        }

        await this.props.loadData();
        this.modal.current.close();
    };

    render() {
        const { settings } = this.props;

        return (
            <Modal
                ref={this.modal}
                trigger={
                    <button id="addAdminButton" type="button" className="btn btn-primary">
                        <IconPlus spaceRight />
                        Add {settings.columns[0].label} User
                    </button>
                }
                title={`Add New ${settings.columns[0].label} User`}
            >
                <AdminForm settings={settings} onSubmit={this.onSubmit} operation="create" />
            </Modal>
        );
    }
}

const mapStateToProps = (state, props) => ({
    // modules: state.app.admin.modules,
});

export default connect(mapStateToProps)(Component);
