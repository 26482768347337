import { createSelector } from 'reselect';

const getGroups = state => state.groups;
const getLicense = state => state.license;

// The site/group selector tree is built recurrsively
// Leaves are the state.level (site/agency/organization/collaborator)
//    and trees are the parent groups of the state.level
// Values of the state.level are the siteIDs they contain

export const getSiteTree = createSelector(getGroups, getLicense, (groups, license) => {
    if (license) {
        groups = groups.filter(group => {
            if (group.group.groupType === 'site' && !group.fields[license]) {
                return false;
            }
            return true;
        });
    }
    function branchHasLeaf(node) {
        // base case node is site/leaf
        if (node.group.groupType === 'site') {
            return true;
        }
        // get node children
        const children = groups.filter(group => group.group.parentId === node.group.groupId);
        return children.some(child => branchHasLeaf(child));
    }
    function growRecursiveTree(node) {
        // Only show trees that have leaves
        if (branchHasLeaf(node)) {
            // base case - node is a leaf
            const children = groups.filter(group => group.group.parentId === node.group.groupId);
            if (children.length === 0 && node.group.groupType === 'site') {
                return {
                    value: node.group.groupId,
                    label: `${node.group.label} (id: ${node.group.groupId})`,
                };
            }
            const childNodes = [];
            children.forEach(child => {
                if (branchHasLeaf(child)) {
                    childNodes.push(growRecursiveTree(child));
                }
            });
            return {
                value: node.group.groupId,
                label: node.group.label,
                children: childNodes.sort((a, b) => a.label.localeCompare(b.label)),
            };
        }
    }
    // Sorting - alphabet sort trees then leaves
    const forest = groups.filter(group => !group.group.parentId).map(root => growRecursiveTree(root));
    const sortedTrees = forest
        .filter(tree => tree) // filter out undefined trees
        .filter(tree => tree.children)
        .sort((a, b) => a.label.localeCompare(b.label));
    const sortedLeaves = forest
        .filter(tree => tree) // filter out undefined trees
        .filter(tree => !tree.children)
        .sort((a, b) => a.label.localeCompare(b.label));
    return [...sortedTrees, ...sortedLeaves];
});

export const getSiteTable = createSelector(getGroups, groups => {
    function recurrsiveAncestoryLookup(node) {
        // base case
        if (!node.group.parentId) {
            return { [node.group.groupType]: node.group.label };
        }
        const parent = groups.filter(group => group.group.groupId === node.group.parentId)[0];
        return {
            [node.group.groupType]: node.group.label,
            ...recurrsiveAncestoryLookup(parent),
        };
    }

    return groups
        .filter(group => group.group.groupType === 'site')
        .map(group => ({ ...recurrsiveAncestoryLookup(group), id: group.group.groupId }));
});
