import React from 'react';
import PropTypes from 'prop-types';
import YouthCoverSheet from './YouthCoverSheet';
import Button from 'cccisd-click-button';
import style from './style.css';

const pdfLib = {
    1: ['TOM 2.0 Form.pdf'],
    2: ['WFI-EZ Care Coordinator Form 2019.pdf', 'WFI-EZ Care Coordinator Form 2019_SPANISH.pdf'],
    3: ['WFI-EZ Caregiver Form 2019.pdf', 'WFI-EZ Caregiver Form 2019_SPANISH.pdf'],
    4: ['WFI-EZ Team Member Form 2019.pdf', 'WFI-EZ Team Member Form 2019_SPANISH.pdf'],
    5: ['WFI-EZ Youth Form 2019.pdf', 'WFI-EZ Youth Form 2019_SPANISH.pdf'],
    6: ['DART.pdf'],
};

export default class PrintView extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        endDate: PropTypes.string,
        tool: PropTypes.string,
        assignmentId: PropTypes.number,
    };

    getSurveyPdfLink = pdf => {
        return 'https://wrapstat.org/api/resources/file/public/' + pdf;
    };

    openPdf = url => {
        var win = window.open(url, '_blank');
        win.focus();
    };

    render() {
        return (
            <div>
                <div className={style.flexIt}>
                    {pdfLib[this.props.assignmentId].map(pdf => (
                        <Button
                            key={pdf}
                            title={pdf}
                            onClick={() => this.openPdf(this.getSurveyPdfLink(pdf))}
                        />
                    ))}
                </div>
                <h4>Youth Cover Sheet</h4>
                <YouthCoverSheet
                    data={this.props.data}
                    tool={this.props.tool}
                    endDate={this.props.endDate}
                />
            </div>
        );
    }
}
