import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import SiteSelector from '../../SiteSelector';
import { connect } from 'react-redux';
import { getSiteTable } from 'js/selectors/sites.js';
import { sample } from 'lodash';
import { setFieldChecked } from 'js/reducers/sites.js';
import InfoLabel from 'js/components/InfoLabel';

class Breadcrumbs extends React.Component {
    static propTypes = {
        setSite: PropTypes.func,
        siteId: PropTypes.number,
        showClosed: PropTypes.bool,
        setShowClosed: PropTypes.func,
        // from redux
        siteTable: PropTypes.array,
        setFieldChecked: PropTypes.func,
        fieldChecked: PropTypes.array,
    };

    state = {
        breadcrumbs: '',
        siteName: '',
    };

    componentDidMount = () => {
        const { siteId } = this.props;
        if (siteId) {
            this.setSingleSite([siteId]);
            this.getBread();
        }
    };

    componentDidUpdate = prevProps => {
        const recentSite = Number(localStorage.getItem('lastViewedSiteId'));

        const siteInSiteTable = this.props.siteTable.some(site => site.id === recentSite);

        if (!this.props.siteId && recentSite && siteInSiteTable) {
            this.setSingleSite([recentSite]);
        } else if (!this.props.siteId && this.props.siteTable.length > 0) {
            const randomSite = sample(this.props.siteTable).id;
            this.setSingleSite([randomSite]);
            localStorage.setItem('lastViewedSiteId', randomSite);
        }
        if (prevProps.siteId !== this.props.siteId || prevProps.siteTable.length !== this.props.siteTable.length) {
            this.getBread();
        }
    };

    setSingleSite = siteId => {
        if (siteId.length !== 0) {
            if (siteId.length > 1) {
                siteId = siteId.filter(site => site !== this.props.fieldChecked[0]);
            }
            this.props.setSite(siteId[0]);
            this.props.setFieldChecked(siteId);
        }
    };

    getBread = () => {
        const ancestory = this.props.siteTable.find(site => site.id === this.props.siteId);
        let loaf = '';
        const seperator = ' / ';
        if (ancestory) {
            if (ancestory.collaborator) {
                loaf += ancestory.collaborator + seperator;
            }
            if (ancestory.organization) {
                loaf += ancestory.organization + seperator;
            }
            if (ancestory.agency) {
                loaf += ancestory.agency + seperator;
            }
            this.setState({ breadcrumbs: loaf + ancestory.site, siteName: ancestory.site });
        }
    };

    isSiteAdmin = () => {
        return window.cccisd.fortress.user.acting.data_type === 'siteAdmin';
    };

    render() {
        return (
            <div className={style.container}>
                <div className={style.flexIt}>
                    <div className={style.siteThing}>
                        <div className={style.nudgeIt}>
                            <SiteSelector singleSelect isFormField onChange={siteId => this.setSingleSite(siteId)} />
                        </div>
                        {!this.isSiteAdmin() && <span className={style.site}>{this.state.siteName}</span>}
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            name="discharged"
                            value="show"
                            checked={this.props.showClosed}
                            onChange={this.props.setShowClosed}
                        />{' '}
                        <InfoLabel tooltip="Shows closed and open cycles" label="Show closed cycles" hideDecorator />
                    </div>
                </div>
                {this.state.breadcrumbs.split('/').length > 1 && (
                    <span className={style.bread}>{this.state.breadcrumbs}</span>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    siteTable: getSiteTable(state.app.sites),
    fieldChecked: state.app.sites.fieldChecked,
});

export default connect(mapStateToProps, { setFieldChecked })(Breadcrumbs);
